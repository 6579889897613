// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCbHdPqD5o4rXUXHuTytL8AZvT294hIJig",
    authDomain: "myprojects.geoapify.com",
    databaseURL: "https://geoapify-console.firebaseio.com",
    projectId: "geoapify-console",
    storageBucket: "geoapify-console.appspot.com",
    messagingSenderId: "334878746503"
  },
  recaptcha_site: {
    siteKey: "6LdQWpoUAAAAAGQQfM0CWPrOB6r6W7lm5BBqpusW"
  },
  ipdata: {
    apiKey: "08aeb28f0922790f1aea87f578e38a5871f6f457e93ec8765055ef91"
  },
  stripe: {
    public: "pk_test_SSj543GkUd50y5MsOjSGAzlj00dhnpMBp7"
  },
  manifoldProviderURL: "http://localhost:4567",
  ip_info: {
    url: "https://api.geoapify.com/v1/ipinfo",
    apiKey: "bef2f33bab7a4e9b825b3e2fa1870745"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
