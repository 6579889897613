import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { RegisterComponent } from '../register/register.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SinglePropertyChangeDialogComponent } from 'src/app/common-components/components/single-property-change-dialog/single-property-change-dialog.component';
import { FunctionsService } from '../../services/functions.service';
import { Router } from '@angular/router';
import { User } from 'firebase/auth';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    standalone: false
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  user: User;
  displayName: string;
  hasPassword: boolean;
  emailVerified: boolean;

  changeType: 'password' | 'email' | 'deleteAccount';
  errorMessage: string;

  emailChangeForm: UntypedFormGroup;
  passwordChangeForm: UntypedFormGroup;
  deleteAccountForm: UntypedFormGroup;

  matcher = new SameStateMatcher();

  inProgress: boolean;

  constructor(private authService: AuthService, private ngZone: NgZone, private fb: UntypedFormBuilder, 
    private snackBar: MatSnackBar, private dialog: MatDialog, private functionService: FunctionsService, private router: Router) {
    this.authService.getUserPromise().then(user => {
      this.user = user;
      this.generateDisplayName(user);

      if (user) {
        this.emailVerified = user.emailVerified;
        this.hasPassword = user.providerData.some(providerData => providerData.providerId === 'password');
      }
    });

    this.createForms();
  }

  createForms() {
    this.passwordChangeForm = this.fb.group({
      password: ['', [Validators.required, RegisterComponent.strong]],
      passwordConfirm: [''],
      oldPassword: ['', [Validators.required]],
    }, { validator: this.checkPasswords });

    this.emailChangeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.deleteAccountForm = this.fb.group({
      password: ['', [Validators.required]]
    });
  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let passwordConfirm = group.controls.passwordConfirm.value;

    return pass === passwordConfirm ? null : { notSame: true }
  }

  generateDisplayName(user) {
    this.displayName = user.email;


    /*if (user.displayName) {
      this.displayName = user.displayName + (user.email ? ` (${user.email})` : '')
    } else {
      this.displayName = user.email;
    }*/
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  tryToChangeEmail(formValue) {
    this.inProgress = true;
    this.authService.setUserEmail(this.user, formValue.password, formValue.email).then(() => {
      this.inProgress = false;
      this.changeType = null;
      this.errorMessage = null;

      this.emailChangeForm.controls.password.setValue('');
      this.emailChangeForm.controls.password.markAsUntouched();
      this.emailChangeForm.controls.email.setValue('');
      this.emailChangeForm.controls.email.markAsUntouched();

      this.ngZone.run(() => {
        this.generateDisplayName(this.user);
      });

    }, err => {
      this.inProgress = false;
      this.errorMessage = err.message;
    });
  }

  tryToChangePassword(formValue) {
    this.inProgress = true;
    this.authService.setUserPassword(this.user, formValue.oldPassword, formValue.password).then(() => {
      this.inProgress = false;
      this.changeType = null;
      this.errorMessage = null;
      this.passwordChangeForm.controls.oldPassword.setValue('');
      this.passwordChangeForm.controls.oldPassword.markAsUntouched();
      this.passwordChangeForm.controls.password.setValue('');
      this.passwordChangeForm.controls.password.markAsUntouched();
      this.passwordChangeForm.controls.passwordConfirm.setValue('');
      this.passwordChangeForm.controls.passwordConfirm.markAsUntouched();


      this.snackBar.open("Password changed", "OK", {
        duration: 2000,
        panelClass: ['message-bar']
      })
    }, err => {
      this.inProgress = false;
      this.errorMessage = err.message;
    });

  }

  tryToDeleteAccount(formValue) {
    this.inProgress = true;
    this.authService.reauthenticateUser(this.user, formValue.password).then(() => {
      this.changeType = null;
      this.errorMessage = null;

      this.deleteAccountForm.controls.password.setValue('');
      this.deleteAccountForm.controls.password.markAsUntouched();

      this.functionService.deleteAccount().then(() => {
        this.inProgress = false;
        this.authService.doLogout().then(() => {
          this.router.navigate(["/login"]);
        });
      }, err => {
        this.inProgress = false;
        console.log(err);
      });

    }, err => {
      this.inProgress = false;
      this.errorMessage = err.message;
    });
  }

  cancelChangeOperation() {
    this.changeType = null;
    this.errorMessage = null;
  }

  deleteAccount() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '450px',
      data: {
        title: "Delete account",
        content: `Are you sure you want to irrecoverably delete your account, projects, and all existing API keys? This operation cannot be reverted. Please type "agree" to confirm:`,
        label: '',
        data: ''
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue === "agree") {
        this.inProgress = true;

        this.functionService.deleteAccount().then(() => {
          this.inProgress = false;
          this.authService.doLogout().then(() => {
            this.router.navigate(["/login"]);
          });
        }, err => console.log(err))
      }
    }); 
  }
}

class SameStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const hasError = !!(control && control.dirty && control.parent && control.parent.hasError('notSame'));
    return hasError;
  }
}