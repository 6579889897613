import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { KeyManagementService } from 'src/app/user/services/key-management.service';
import { Project } from 'src/app/user/services/project.service';
import { PricingPlan } from '../api-pricing-plan-item/api-pricing-plan-item.component';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
Chart.register(...registerables);
@Component({
    selector: 'app-overview-statistics',
    templateUrl: './overview-statistics.component.html',
    styleUrls: ['./overview-statistics.component.scss'],
    standalone: false
})
export class OverviewStatisticsComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  project: Project;

  @Input()
  fromDate: Date;

  @Input()
  toDate: Date;

  @Input()
  key: string;

  @ViewChild('chart')
  chartContainer: ElementRef;

  statisticsInProgress: boolean;
  statisticsToDisplay = ['tiles', 'staticmap', 'icon', 'geocoding', 'geolocation', 'places', 'placedetails', 'boundaries', 'isoline', 'routing', 'mapmatching', 'routematrix', 'routeplanner', 'batch'];

  statisticsPerDay = {};

  geoapifyPricingPlans: PricingPlan[];
  creditsPerDay: number;

  chart: Chart;
  initialized: boolean;

  constructor(private keyManagementService: KeyManagementService, private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && changes.project.currentValue && !this.initialized) {
      this.initialized = true;
      this.initPricingPlanData();
      this.updateStatistics();
    }
  }

  ngAfterViewInit(): void {
    this.chart = new Chart(this.chartContainer.nativeElement, {
      type: 'line',
      options: {
        scales: {
          x: {
            type: 'time',
          }
        }
      },
      data: {
        labels: [],
        datasets: []
      }
    });


    this.http.get("assets/json/pricing-plans-new.json").subscribe((data: any) => {
      this.geoapifyPricingPlans = data;

      if (this.project) {
        this.initialized = true;
        this.initPricingPlanData();
        this.updateStatistics();
      }
    });
  }

  initPricingPlanData() {
    if (!this.geoapifyPricingPlans || !this.project) {
      return;
    }

    const currentPlanId = this.project.billing?.plan || 'geoapify-api-free';
    const currentPlan = this.geoapifyPricingPlans.find(plan => plan.id === currentPlanId);

    if (currentPlan.type === 'credits-based') {
      this.creditsPerDay = currentPlan.credits;
    } else {
      this.creditsPerDay = 0;
    }
  }

  public updateStatistics() {
    if (!this.project|| !this.geoapifyPricingPlans) {
      return;
    }

    const keys = Object.keys(this.project.keys || {}).filter(key => !this.key || this.key === 'all' || key === this.key);

    this.statisticsInProgress = true;

    this.statisticsPerDay = {};

    let fromDateWith0 = new Date(this.fromDate);
    fromDateWith0.setHours(0);
    fromDateWith0.setMinutes(0);
    fromDateWith0.setSeconds(0);

    let toDateWith24 = new Date(this.toDate);
    toDateWith24.setHours(24);
    toDateWith24.setMinutes(0);
    toDateWith24.setSeconds(0);

    var days =  Math.floor(( toDateWith24.getTime() - fromDateWith0.getTime() ) / 86400000); 

    for (let i = 0; i <= days; i++) {
      const date = new Date(fromDateWith0);
      date.setDate(date.getDate() + i);
      this.statisticsPerDay[date.toISOString().substring(0, 10)] = {}
    }

    const fromDateStr = fromDateWith0.toISOString()
    const toDateStr = toDateWith24.toISOString()

    const promises = [];
    keys.forEach((key) => {
      promises.push(this.keyManagementService.getApiKeyStatistics(key, fromDateStr, toDateStr, true));
    });

    Promise.all(promises).then(dataArr => {

      this.statisticsInProgress = false;

      dataArr.forEach((data, index) => {

        if (!data.result) {
          return;
        }

        const statisticsData = data.result[keys[index]];
        
        this.statisticsToDisplay.forEach(field => {

          const fieldData = statisticsData[field];
          
          Object.keys(fieldData.day || {}).forEach(date => {
            this.statisticsPerDay[date] = this.statisticsPerDay[date] || {};
            this.statisticsPerDay[date][keys[index]] = this.statisticsPerDay[date][keys[index]] || 0;
            this.statisticsPerDay[date][keys[index]] += fieldData.day[date].cost_credits;
          });
        });
      });

      this.generateChartData(keys);
    }, () => this.statisticsInProgress = false);
  }

  generateChartData(keys: string[]) {
    if (!this.chartContainer || !this.statisticsPerDay) {
      return;
    }

    const labels = Object.keys(this.statisticsPerDay);

    const datasets = [];

    // add credits limits
    if (this.creditsPerDay) {
      const data = [];

      Object.keys(this.statisticsPerDay).forEach((day) => {
  
        data.push(            {
          x: day,
          y: this.creditsPerDay
        },)
      });  
  
      let dataSet = {
        label: "Credits limit",
        data: data,
        borderColor: "#ff5050",
        fill: false,
        borderWidth: 2
      };
  
      datasets.push(dataSet);
    }

    // add credits total
    const data = [];
    Object.keys(this.statisticsPerDay).forEach((day) => {
      
      let credits = 0;

      Object.keys(this.statisticsPerDay[day]).forEach(value => {
        credits += this.statisticsPerDay[day][value];
      });

      data.push(            {
        x: day,
        y: credits
      },)
    });  

    const dataSet = {
      label: "Credits used",
      data: data,
      borderColor: "#0099ff",
      fill: false,
      borderWidth: 2
    };

    datasets.push(dataSet);

    const colors = ["#33cccc", "#9999ff", "#ff99ff", "#ff9999", "#ffff66", "#66ccff"];

    keys.forEach((key, index) => {

      const data = [];

      Object.keys(this.statisticsPerDay).forEach((day) => {
        data.push(            {
          x: day,
          y: this.statisticsPerDay[day][key] || 0
        },)
      });  

      const dataSet = {
        label: this.addKeyDescription(key),
        data: data,
        borderColor: colors[index % colors.length],
        fill: false,
        borderWidth: 1
      };

      datasets.push(dataSet);
    });

    this.chart.data.labels = labels;
    this.chart.data.datasets = datasets;
    this.chart.update();
  }

  addKeyDescription(key: string): string {
    if (this.project?.keys[key] && this.project.keys[key].description) {
      return `${this.maskKey(key)} (${this.project.keys[key].description})`
    } 

    return key;
  }

  maskKey(key: string): string {
    if (!key || key.length < 10) {
      return key; // If the key is too short, return it as is (no masking)
    }
    const visibleStart = key.slice(0, 5); // Show the first 4 characters
    const visibleEnd = key.slice(-5); // Show the last 4 characters
    const maskedPart = '*'.repeat(key.length - 10); // Replace the middle part with '*'
    return `${visibleStart}${maskedPart}${visibleEnd}`;
  }
}
