<div class="user-verification-container">
  <div class="mat-h1">Verify your email</div>
  <div class="mat-body">We have sent a verification link to <i>{{ userData.email }}</i>. Please verify your email address by following the link and refresh the page.</div>

  <div class="user-verification-actions margin-top-20" *ngIf="!showResendControls">
    <button mat-raised-button (click)="reloadPage()" class="btn btn-default" color="accent">Reload</button>
    <button mat-raised-button (click)="showResendControls=true" class="btn btn-default">Resend the verification
      email</button>
  </div>

  <div class="margin-top-20" *ngIf="showResendControls">
    <re-captcha (resolved)="resolved($event)"></re-captcha>
    <div>
      <button mat-raised-button [disabled]="!verificationRecaptcha" (click)="resendVerificationEmail()"
        class="btn btn-default">Send the verification email</button>
    </div>
  </div>
</div>