<div class="project-keys-container">
  <div class="spinner-container" *ngIf="inProgress">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <app-data-widget widgetCaption="API keys">
    <div class="keys-container">
      <div class="key-container" *ngFor="let apiKey of apiKeys; let i = index">
        <app-key-item [key]="apiKey.id" [description]="apiKey.description"
          (changeDescription)="changeDescription(apiKey, $event)" (removeKey)="removeApiKey($event, i)"
          [openStates]="openStates" [projectData]="projectDataString">
        </app-key-item>
      </div>
      <div class="keys-actions">
        <button mat-icon-button color="accent" (click)="createApiKey()">
          <mat-icon aria-label="Create a new API Key">add_circle</mat-icon>
        </button>
      </div>
    </div>
  </app-data-widget>
</div>

<div class="code-samples-container">
  <app-data-widget widgetCaption="Try API and generate code" borderOnly="true" >
    <app-code-template [keys]="apiKeys"></app-code-template>
  </app-data-widget>
</div>