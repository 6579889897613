<div class="welcome-block" *ngIf="projects && !projects?.length">

  <div class="projects-container first">
    <div class="mat-title title white-08">Welcome to Geoapify MyProjects!</div>
    <div class="mat-body sub-title white-06">Here you can create and manage your API keys that are required to make calls to
      Geoapify APIs</div>
    <button color="accent" mat-flat-button (click)="openNewProjectDialog()">Create a project</button>
  </div>
</div>

<div class="projects-container" *ngIf="user && !user.emailVerified">
  <app-user-verification [userData]="user" ></app-user-verification>
</div>

<div class="projects-container first" *ngIf="projects?.length">
  <div class="mat-title black-08">Projects</div>
  <div class="project-container new-project" (click)="openNewProjectDialog()">
    <div class="centered">
      <fa-icon class="color-accent" [icon]="['fas', 'plus-circle']" size="lg"></fa-icon>
      <div class="title mat-title margin-top-10">Add a new project</div>
    </div>
  </div>
  <div class="project-container project" *ngFor="let project of projects" (click)="openProjectDetails(project)">
    <div class="project-content">
      <div class="title mat-body" [matTooltip]="project.name">{{project.name}}</div>
      <div class="subtitle mat-body margin-top-10" *ngIf="project.shared">
        <span class="margin-right-10"><fa-icon class="color-accent" [icon]="['fas', 'share-alt']"></fa-icon></span>Shared with you
      </div>
      <div class="type-info mat-body" *ngIf="project.type === 'api'">Geoapify APIs project</div>
    </div>
  </div>
</div>

<div class="projects-container">
  <mat-divider></mat-divider>
</div>

<div class="projects-container last">
  <app-data-widget widgetCaption="Create a project and generate an API key" borderOnly="true">
    <div class="container-with-background">
      <div class="black-06">An API key is required to make and authorize requests to Geoapify API:
      </div>
      <ul class="mat-caption black-08 overlapped-text">
        <li>API keys are grouped and managed within Projects</li>
        <li>Project can represent a business entity, department, website, or application</li>
        <li>You can have many Projects per account, and multiple API keys per Project</li>
        <li>Billing and usage plan is configured on Project level</li>
        <li>You can share access to a Project with your clients and colleagues</li>
      </ul>
    </div>
    <div class="margin-top-20 mat-body black-08">
      Please manage your account, projects, and API keys carefully and responsibly. 
      Creating multiple accounts and projects to distribute the same usage across them can be a violation of our <a target="_blank" href="https://www.geoapify.com/term-and-conditions">terms and conditions</a>.
      Please contact us <a href="mailto:info@geoapify.com">info&#64;geoapify.com</a> if you have any questions.
    </div>
  </app-data-widget>
</div>

<app-footer></app-footer>