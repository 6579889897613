import {Component, Input, OnInit, Output} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {User} from 'firebase/auth';

@Component({
    selector: 'app-user-verification',
    templateUrl: './user-verification.component.html',
    styleUrls: ['./user-verification.component.scss'],
    standalone: false
})
export class UserVerificationComponent implements OnInit {

  showResendControls = false;
  verificationRecaptcha = false;

  @Input()
  userData: User;

  constructor(private authService: AuthService) { 

  }

  ngOnInit() {
  }

  public resendVerificationEmail() {
    this.authService.doSendVerificationEmail().then( () => {
        this.showResendControls = false;
    },err => {
      this.showResendControls = false;
      console.log(err);
    });
  }

  reloadPage() {
    window.location.reload();
  }

  resolved(captchaResponse: string) {
    this.verificationRecaptcha = true;
    //console.log(`Resolved captcha with response ${captchaResponse}:`);
  }
}
