import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/user/services/auth.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-manifold-dashboard',
    templateUrl: './manifold-dashboard.component.html',
    styleUrls: ['./manifold-dashboard.component.scss'],
    standalone: false
})
export class ManifoldDashboardComponent implements OnInit, OnDestroy {

  userSubsctiption: Subscription;
  keys: string[] = [];

  activePanelId: string;
  activePanelIdSub: Subscription;

  navigationMinimized: boolean;

  openStates: { [key: string]: boolean } = {};

  navigationItems: any[] = [
    { id: "keys", label: "API keys", icon: "key" },
    { id: "statistics", label: "Statistics", icon: "chart-bar" }
  ]

  docs: any[] = [];
  samples: any[] = [];

  constructor(private router: Router, private authService: AuthService,
    private activatedRoute: ActivatedRoute, private httpClient: HttpClient, private ngZone: NgZone, sanitizer: DomSanitizer) {

    this.docs = [
      { label: "Maps", icon: "map", url: sanitizer.bypassSecurityTrustUrl('https://apidocs.geoapify.com/playground/maps') },
      { label: "Geocoding API", icon: "search", url: sanitizer.bypassSecurityTrustUrl('https://apidocs.geoapify.com/playground/geocoding') },
      { label: "Routing API", icon: "route", url:  sanitizer.bypassSecurityTrustUrl('https://apidocs.geoapify.com/playground/routing') },
      { label: "Places API", icon: "map-marker-alt", url: sanitizer.bypassSecurityTrustUrl('https://apidocs.geoapify.com/playground/places') },
      { label: "Isoline API", icon: "splotch", url: sanitizer.bypassSecurityTrustUrl('https://apidocs.geoapify.com/playground/isoline') },
    ];

    if (authService.getCurrentUser()) {
      this.initKeys();
    }

    this.userSubsctiption = authService.getUserChangeEvent().subscribe(user => {
      if (user) {
        this.initKeys();
      } else {
        this.keys = [];
      }
    });

    this.router.navigate([], {
      queryParams: {
        auth: null
      },
      queryParamsHandling: 'merge'
    })

    this.activePanelIdSub = this.activatedRoute.params.subscribe(params => {
      this.activePanelId = params['panelId'];
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.userSubsctiption.unsubscribe();
    this.activePanelIdSub.unsubscribe();
  }

  onNavItemClick(item: any) {
    this.router.navigate([`../${item.id}`], { relativeTo: this.activatedRoute });
  }

  private initKeys() {
    this.authService.getUserTokenId().then((tokenId: string) => {
      this.httpClient.get(`${environment.manifoldProviderURL}/keys`, { headers: { 'User-Token-ID': tokenId } }).subscribe((keys: string[]) => {
        this.ngZone.run(() => {
          this.keys = keys
        });
      }, error => {
        console.log(error);
      });
    });
  }
}
