import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { getAuth, User, FacebookAuthProvider, signInWithCustomToken, signInWithPopup, sendEmailVerification, GoogleAuthProvider, 
  createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged, EmailAuthProvider,
  reauthenticateWithCredential, updatePassword, updateEmail } from '@firebase/auth';
import { collection, doc, getFirestore, setDoc, Unsubscribe } from "firebase/firestore";
@Injectable()
export class AuthService implements OnDestroy {

  private currentUser: User;
  private userChangeEvent: EventEmitter<User> = new EventEmitter();

  private unsubscribeUserChangeEvent: Unsubscribe;
  private tokenClaims: {
    [key: string]: any;
  } = {};
 

  constructor() {
    this.unsubscribeUserChangeEvent = getAuth().onAuthStateChanged((user) => {
      if (!user) {
        this.currentUser = user;
        this.userChangeEvent.emit(user);
      } else {
        user.getIdTokenResult().then((result) => {
          this.tokenClaims = result.claims;
          this.currentUser = user;
          this.userChangeEvent.emit(user);
        });
      }
    });
  }

  doLoginWithCustomToken(value: string) {
    return new Promise<any>((resolve, reject) => {
      signInWithCustomToken(getAuth(), value)
        .then(res => {
          resolve(res);
        }, err => reject(this.mapAuthErrorToUserFriendly(err)))
    })
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new FacebookAuthProvider();
      signInWithPopup(getAuth(), provider)
        .then(res => {
          const user = getAuth().currentUser;

          if (!user.emailVerified) {
            sendEmailVerification(user);
          }
          
          resolve(res);
        }, err => {
          reject(this.mapAuthErrorToUserFriendly(err));
        })
    })
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      signInWithPopup(getAuth(), provider)
        .then(res => {
          resolve(res);
        }, err => {
          reject(this.mapAuthErrorToUserFriendly(err));
        })
    })
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(getAuth(), value.email, value.password)
        .then(() => {
          const user = getAuth().currentUser;
          const ref = doc(collection(getFirestore(), 'users'), user.uid);
          setDoc(ref, {
            newsletterConsent: 'skipped'
          }, { merge: true }).then(() => {
            resolve(user);
          }, err => console.log(err));
        })
        .catch(err => reject(this.mapAuthErrorToUserFriendly(err)));
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(this.mapAuthErrorToUserFriendly(err)))
    })
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (getAuth().currentUser) {
        signOut(getAuth()).then(() => {
          this.getUserChangeEvent().emit(null);
          resolve(true);
        });
      }
      else {
        reject();
      }
    });
  }

  doSendPasswordResetEmail(email: string) {
    return new Promise<any>((resolve, reject) => {
      sendPasswordResetEmail(getAuth(), email)
        .then(res => {
          resolve(res);
        }, err => reject(this.mapAuthErrorToUserFriendly(err)))
    });
  }

  doSendVerificationEmail() {
    return new Promise<any>((resolve, reject) => {
      this.getUserPromise().then(user => {
        sendEmailVerification(user).then((res) => {
          resolve(res)
        }, err => reject(this.mapAuthErrorToUserFriendly(err)));
      })
    });
  }

  public getCurrentUser(): User {
    return this.currentUser;
  }

  public isRegularUser(): boolean {
    return !this.tokenClaims.userType;
  }

  public isManifoldUser(): boolean {
    return this.tokenClaims.userType === 'manifold'
  }

  public getTokenClaims(): {[key: string]: any} {
    return this.tokenClaims;
  }

  public getUserChangeEvent(): EventEmitter<User> {
    return this.userChangeEvent;
  }

  public getUserPromise(): Promise<User> {
    return new Promise<any>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
        unsubscribe();
        if (user) {
          user.getIdTokenResult().then((result) => {
            this.tokenClaims = result.claims;
            resolve(user);
          });
        } else {
          reject(null);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeUserChangeEvent();
  }

  getUserTokenId():Promise<string> {
    return this.currentUser.getIdToken();
  }

  setUserPassword(user: User, currentPassword: string, newPassword: string) {
    const credentials = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    return new Promise<any>((resolve, reject) => {
      reauthenticateWithCredential(user, credentials).then(() => {
        updatePassword(user, newPassword).then(() => resolve(true), err => reject(this.mapAuthErrorToUserFriendly(err)))
      }, err => reject(this.mapAuthErrorToUserFriendly(err)))
    });
  }

  setUserEmail(user: User, password: string, email: string) {
    const credentials = EmailAuthProvider.credential(
      user.email,
      password
    );

    return new Promise<any>((resolve, reject) => {
      reauthenticateWithCredential(user, credentials).then(() => {
        updateEmail(user, email).then(() => {
          sendEmailVerification(user).then(() => resolve(true), err => reject(this.mapAuthErrorToUserFriendly(err)));
        }, err => reject(this.mapAuthErrorToUserFriendly(err)))
      }, err => reject(this.mapAuthErrorToUserFriendly(err)))
    });
  }

  reauthenticateUser(user: User, password: string) {
    const credentials = EmailAuthProvider.credential(
      user.email,
      password
    );

    return new Promise<any>((resolve, reject) => {
      reauthenticateWithCredential(user, credentials).then(() => resolve(true), err => reject(this.mapAuthErrorToUserFriendly(err)))
    });
  }

  mapAuthErrorToUserFriendly(error) {

    switch (error.code) {
      case "auth/invalid-password":
        return {code: error.code, message: "The password user property needs to be a string with at least six characters." };
  
      case "auth/wrong-password":
          return {code: error.code, message: "Password is incorrect. Please try again." };
    
      case "auth/invalid-email":
        return {code: error.code, message: "Oops! It looks like your email address is invalid." };

      case "auth/email-already-in-use":
        return {code: error.code, message: "An account for this email address already exists. Each user must have a unique email." };

      case "auth/email-already-exists":
        return {code: error.code, message: "An account for this email address already exists. Each user must have a unique email." };

      case "auth/internal-error":
          return {code: error.code, message: "Sorry, something went wrong. Please check the email and password you entered." };
  
      case "auth/popup-closed-by-user":
          return {code: error.code, message: "" };

      case "auth/cancelled-popup-request":
          return {code: error.code, message: "" };

      case "auth/user-not-found":
            return {code: error.code, message: "Sorry, we couldn't find a user account for the email address you entered." };

      // Many more authCode mapping here... See https://firebase.google.com/docs/auth/admin/errors

      default:
        return error;
    }
  }
}
