import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ProjectMinimal, UserDataService } from 'src/app/user/services/user-data.service';
import { Project } from 'src/app/user/services/project.service';
import { ActiveApiProjectService } from '../../services/active-api-project.service';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-api-project-navigation',
    templateUrl: './api-project-navigation.component.html',
    styleUrls: ['./api-project-navigation.component.scss'],
    standalone: false
})
export class ApiProjectNavigationComponent implements OnInit, OnDestroy {

  items: NavigationItem[] = [
    { id: 'settings', label: 'Project overview', icon: 'cog' },
    { id: 'keys', label: 'API keys', icon: 'key' },
    { id: 'statistics', label: 'Statistics', icon: 'chart-bar'},
    { id: 'billing', label: 'Billing', icon: 'credit-card' }
  ]

  activeProject: Project;
  activeProjectId: string;
  minimized: boolean;
  existingProjects: ProjectMinimal[];
  projectSub: Subscription;
  userSub: Subscription;

  activeItemId: string;
  activePanelIdSub: Subscription;

  constructor(private userService: UserDataService,
    private activeProjectService: ActiveApiProjectService,
    private router: Router,
    private ngZone: NgZone) {

    this.projectSub = this.activeProjectService.getActiveProjectObservable().subscribe((project) => {
      if (!project) {
        return;
      }

      this.activeProject = project;
      this.activeProjectId = this.activeProjectService.getActiveProjectId();

      if (this.userService.getUserDocument()) {
        this.initExistingProjects();
      }
    });

    this.userSub = this.userService.getUserDocumentChangeEvent().subscribe(() => {
      this.initExistingProjects();
    });

    this.activePanelIdSub = this.activeProjectService.getActivePanelIdObservable().subscribe((id) => {
      if (!id) {
        return;
      }
      
      this.activeItemId = id;
    });
  }

  ngOnInit() {

  }

  togglePosition() {
    this.minimized = !this.minimized;
  }

  initExistingProjects() {
    this.ngZone.run(() => {
      this.existingProjects = this.userService.getProjects();
    });
  }

  projectChange(event: MatSelectChange) {
    if (event.value === 'see all') {
      this.router.navigate(['/projects']);
    } else {
      const project = this.existingProjects.filter((project) => project.id === event.value)[0];
      this.router.navigate([`/${project.type}`, project.id]);
    }
  }

  onNavItemClick(item: NavigationItem) {
    this.router.navigate([`/${this.activeProject.type}`, this.activeProjectId, item.id]);

  }

  ngOnDestroy() {
    this.projectSub.unsubscribe();
    this.activePanelIdSub.unsubscribe();
    this.userSub.unsubscribe();
  }
}

interface NavigationItem {
  id: string;
  label: string;
  icon: string;
}