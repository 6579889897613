import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from 'src/app/user/services/auth.service';

@Injectable()
export class ManifoldGuard  {

  constructor(
    public authService: AuthService,
    private router: Router ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Promise<boolean>{
    return new Promise((resolve) => {
      const authToken = activatedRoute.queryParams["auth"];
      if (authToken) {
        this.authService.doLoginWithCustomToken(authToken).then(() => {
          resolve(true);
        }, () => {
          this.router.navigate(['/manifold/login']);
          resolve(false);
        });
      } else {
        this.authService.getUserPromise().then(() => {
          if (this.authService.isManifoldUser()) {
            resolve(true)
          } else {
            this.router.navigate(['/projects']);
            resolve(false);
          }
        }, () => {
          this.router.navigate(['/manifold/login']);
          resolve(false);
        });
      }
    });
  }
}
