import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-login-background',
    templateUrl: './login-background.component.html',
    styleUrls: ['./login-background.component.scss'],
    standalone: false
})
export class LoginBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
