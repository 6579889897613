import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./components/login/login.component";
import {environment} from "../../environments/environment";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip"; 
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { UserVerificationComponent } from './components/user-verification/user-verification.component';
import { RegisterComponent } from './components/register/register.component';
import { RestorePasswordComponent } from './components/restore-password/restore-password.component';
import { LoginBackgroundComponent } from './components/login-background/login-background.component';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { UserProjectsComponent } from './components/user-projects/user-projects.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import {PaymentDataComponent} from "./components/payment-data/payment-data.component";
import { UserAdditionalDataComponent } from './components/user-additional-data/user-additional-data.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { NewProjectDialogComponent } from './components/new-project-dialog/new-project-dialog.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTabsModule,
        MatCheckboxModule,
        MatSelectModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatDialogModule,
        MatDividerModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        CommonComponentsModule,
        RouterModule
    ],
    declarations: [
        LoginComponent,
        UserVerificationComponent,
        RegisterComponent,
        RestorePasswordComponent,
        LoginBackgroundComponent,
        UserProjectsComponent,
        UserSettingsComponent,
        PaymentDataComponent,
        UserAdditionalDataComponent,
        ProjectsComponent,
        NewProjectDialogComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptcha_site.siteKey } as RecaptchaSettings,
        }
    ]
})
export class UserModule { }
