<app-login-background>

  <div class="flex flex-row flex-mobile-column">
    <div class="left-panel margin-right-20 margin-right-50">
      <div class="margin-bottom-100 mobile-margin-bottom-0">
        <div class="decoration-title-1">Welcome to</div>
        <div class="decoration-title-1">Geoapify Location Platform</div>
        <div class="mat-caption black-06 itallic hidden-mobile">Here you can create and manage your projects and API keys</div>
      </div>
      <img class="fullwidth max-width-300 hidden-mobile" src="assets/img/code_thinking-800.png"/>
    </div> 
    <div class="right-panel flex-1">

      <form class="login-form full-width" [formGroup]="loginForm">
        <div>
          <mat-form-field class="login-form-control-fullwidth" hideRequiredMarker="true">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" class="form-control" >
          </mat-form-field>


          <mat-form-field class="login-form-control-fullwidth" hideRequiredMarker="true">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" class="form-control" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" matTooltip="Toggle password visibility" type="button">
              <fa-icon [icon]="hide ? ['fas', 'eye-slash'] : ['fas', 'eye'] "></fa-icon>
            </button>
          </mat-form-field>
          


          <div *ngIf="errorMessage" class="login-form-error-message">
            <span class="mat-caption">{{errorMessage}}</span>
          </div>
          <div class="mat-caption black-06 text-right"><a [routerLink]="['/restore']">Forgot password?</a></div>
          <div class="login-form-button margin-top-20">
            <button mat-flat-button color="primary" type="submit" (click)="tryLogin(loginForm.value)" class="btn btn-default">Login</button>
          </div>
        </div>
      </form>

      <div class="horisontal-line-with-text"><span class="text mat-caption black-06">or continue with</span></div>
    
      <div class="buttons flex flex-row justify-center margin-top-30">
        <div class="margin-right-20">
          <button mat-stroked-button	class="icon-button google" (click)="tryGoogleLogin()">
            <fa-icon  class="icon google margin-right-10" [icon]="['fab', 'google']" size="lg"></fa-icon> <span class="text">Google</span>
          </button>
        </div>
        <div class="icon-button">
          <button mat-stroked-button class="icon-button facebook black-08" (click)="tryFacebookLogin()">
            <fa-icon class="icon facebook margin-right-10" [icon]="['fab', 'facebook']" size="xl"></fa-icon> <span class="text">Facebook</span>
          </button>
        </div>
      </div>

    
      <div class="mat-body black-08 margin-top-20 text-center">
        Not registered yet? <a [routerLink]="['/register']">Create an Account</a>
      </div>
    </div>
  </div>

</app-login-background>








