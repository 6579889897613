import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService} from "../services/auth.service";

@Injectable()
export class UserGuard  {

  constructor(
    public authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.authService.getUserPromise().then(() => {
        if (this.authService.isRegularUser()) {
          resolve(true);  
        } else {
          this.router.navigate(['/manifold/dashboard']);
          resolve(false);
        }
      }, () => {
        this.router.navigate(['/login']);
        resolve(false);
      });
    });
  }
}
