import { Component, OnInit, Input, NgZone, SimpleChange, SimpleChanges } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { FunctionsService } from '../../services/functions.service';

@Component({
    selector: 'app-payment-data',
    templateUrl: './payment-data.component.html',
    styleUrls: ['./payment-data.component.scss'],
    standalone: false
})
export class PaymentDataComponent implements OnInit {

  handler: any;
  displayedColumns: string[] = ['name', 'default', 'remove'];

  inProgress = false;
  sources: any[] = [];

  constructor(public functionService: FunctionsService, private authService: AuthService, private dialog: MatDialog,
    private ngZone: NgZone) { 
  }

  ngOnInit() {
    this.configHandler();

    /* if (this.customerData) {
      this.sources = [... this.customerData.sources.data];
    }*/
  }

  private configHandler() {
    /*this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      image: 'https://geoapify-dev.firebaseapp.com/assets/img/icon-blue.png',
      locale: 'auto',
      name: 'Geoapify',
      description: 'Add a card to payment methods',
      label: 'Add',
      allowRememberMe: false,
      panelLabel: 'Add',
      source: source => {
        this.inProgress = true;
        this.stripeService.addSourceToStripeCustomer({sourceId : source.id})
          .then(() => {
            this.inProgress = false;
            this.sources.push(source);
          }, () => this.inProgress = false);
      }
    });*/
  }

  openHandler() {
    
    /*this.handler.open({
      email: this.authService.getCurrentUser() ? this.authService.getCurrentUser().email : null
    });*/
  }

 /* generateName(source, short?: boolean): string {
    return short ? 
    `${source.card.brand} (****.****.****.${source.card.last4})` :
    `${source.card.brand} (****.****.****.${source.card.last4}), exp. ${source.card.exp_month}/${source.card.exp_year}`;
  }

  isDefault(source): boolean {
    return this.customerData.default_source === source.id;
  }


  makeSourceDeafault(source: any) {
    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {title: "Default payment source", content: `Make ${this.generateName(source, true)} default payment source?`}
      });
  
      dialogRef.afterClosed().subscribe(okPressed => {
        if (okPressed) {
            this.inProgress = true;
            this.stripeService.makeSourceDefault({sourceId : source.id})
              .then(() => {
                this.inProgress = false;
                this.customerData.default_source = source.id;
              }, () => this.inProgress = false);
        }
      });
    });
  }

  removePaymentSource(source: any, index: number) {
    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {title: "Detatch payment source", content: `Detatch ${this.generateName(source, true)} from the account payment sources?`}
      });
  
      dialogRef.afterClosed().subscribe(okPressed => {
        if (okPressed) {
          this.inProgress = true;
          this.stripeService.detatchSource({sourceId : source.id})
            .then(() => {
              this.inProgress = false;
              this.sources.splice(index, 1);
            }, () => this.inProgress = false);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerData && changes.customerData.currentValue) {
      const value = changes.customerData.currentValue;

      if (value.sources.data.length !== this.sources.length) {
        this.sources = [...value.sources.data];
      }
    }
  }*/
}
