<div class="key-item-container">
    <div class=api-key-title-container>
        <div class="api-key-title black-08" [ngClass]="{'minimized': minimized}">
            <span class="mat-body-2">Key:</span>

            <span class="mat-body margin-left-20">{{key}}</span>

            <span *ngIf="minimized && !onlyKey" class="mat-caption black-06 margin-left-10 minimized-description">({{description}})</span>
            <span class="margin-left-5 copy-button">
                <button mat-icon-button (click)="copyToClipboard(key)" matTooltip="Copy to clipboard">
                    <fa-icon class="black-06" [icon]="['fas', 'copy']"></fa-icon>
                </button>
            </span>
        </div>
        <div class="api-key-title-actions">
            <button mat-icon-button color="primary" *ngIf="!minimized" (click)="setState(true)">
                <mat-icon area-label="Show more">expand_less</mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="minimized" (click)="setState(false)">
                <mat-icon area-label="Show more">expand_more</mat-icon>
            </button>
        </div>
    </div>

    <div class="" [style.display]="minimized ? 'none' : 'block'">
        <div class="setting-container" *ngIf="!onlyKey">
            <span class="label mat-body-2 setting-item">Description: </span>
            <span class="name mat-body setting-item">{{description ? description : 'No description provided'}}</span>
            <span class="edit-button setting-item">
                <button mat-icon-button (click)="editDescription()" matTooltip="Change description">
                    <fa-icon class="black-06" [icon]="['fas', 'pen']"></fa-icon>
                </button>
            </span>
        </div>
        <div class="divider-container">
            <mat-divider></mat-divider>
        </div>
        <div class="two-columns-container setting-container">
            <div class="spinner-container" *ngIf="inProgress">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div class="first-column">
                <div class="mat-body2 api-key-subtitle" color="accent">
                    <span>Allowed IP addresses</span>
                    <mat-icon class="icon" inline="true" [matTooltip]="ipAddressHint">info</mat-icon>
                </div>

                <div class="key-item-list-settings">
                    <div class="key-item-list-setting-item mat-caption"
                        *ngFor="let restrictionAddress of apiKeyData?.restriction_addresses; let i = index"
                        (click)="editRestrictionAddress(i)">
                        <span class="key-item-list-setting-item-value">{{ restrictionAddress }}</span>
                        <button mat-icon-button (click)="$event.stopPropagation(); removeRestrictionAddress(i)">
                            <mat-icon aria-label="Remove the restriction address">delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="key-item-list-setting-add">
                    <button mat-icon-button (click)="addRestrictionAddress()" color="primary">
                        <mat-icon aria-label="Add restriction address">add_circle</mat-icon>
                    </button>
                </div>
            </div>
            <div class="second-column">
                <div class="mat-body2 api-key-subtitle" color="accent">
                    <span>Allowed HTTP referrers</span>
                    <mat-icon class="icon" inline="true" [matTooltip]="httpReferrerHint">info</mat-icon>
                </div>

                <div class="key-item-list-settings">
                    <div class="key-item-list-setting-item mat-caption"
                        *ngFor="let restrictionReferrer of apiKeyData?.restriction_referrers; let i = index"
                        (click)="editRestrictionReferrer(i)">
                        <span class="key-item-list-setting-item-value">{{ restrictionReferrer }}</span>
                        <button mat-icon-button (click)="$event.stopPropagation(); removeRestrictionReferrer(i)">
                            <mat-icon aria-label="Remove the restriction referrer">delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="key-item-list-setting-add">
                    <button mat-icon-button (click)="addRestrictionReferrer()" color="primary">
                        <mat-icon aria-label="Add restriction referrer">add_circle</mat-icon>
                    </button>
                </div>
            </div>

            <div class="third-column">
                <div class="mat-body2 api-key-subtitle" color="accent">
                    <span>Allowed Origins</span>
                    <mat-icon class="icon" inline="true" [matTooltip]="originsHint">info</mat-icon>
                </div>

                <div class="key-item-list-settings">
                    <div class="key-item-list-setting-item mat-caption"
                        *ngFor="let origin of apiKeyData?.restriction_origins; let i = index"
                        (click)="editRestrictionOrigin(i)">
                        <span class="key-item-list-setting-item-value">{{ origin }}</span>
                        <button mat-icon-button (click)="$event.stopPropagation(); removeRestrictionOrigin(i)">
                            <mat-icon aria-label="Remove the restriction origin">delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="key-item-list-setting-add">
                    <button mat-icon-button (click)="addRestrictionOrigin()" color="primary">
                        <mat-icon aria-label="Add restriction origin">add_circle</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="setting-container margin-top-20" *ngIf="apiKeyData">
            <span class="label long mat-body-2 setting-item" [matTooltip]="corsHint">CORS Access-Control-Allow-Origin:
            </span>
            <span class="name mat-body setting-item"
                [class.empty]="!apiKeyData.restriction_allowed_origin_header">{{apiKeyData.restriction_allowed_origin_header || '*'}}</span>
            <span class="edit-button setting-item">
                <button mat-icon-button (click)="editAllowedOrigin()" matTooltip="Change origin">
                    <fa-icon class="black-06" [icon]="['fas', 'pen']"></fa-icon>
                </button>
            </span>
        </div>

        <div class="divider-container">
            <mat-divider></mat-divider>
        </div>
        
        <div class="key-item-actions" *ngIf="!onlyKey">
            <button mat-raised-button (click)="removeAPIKey()">
                <fa-icon class="black-06 margin-right-10" [icon]="['fas', 'trash']"></fa-icon>Remove key
            </button>
        </div>
    </div>
</div>