<div>
  <div class="data-container">

    <div *ngIf="!user.emailVerified" class="user-verification-container">
      <app-data-widget>
        <app-user-verification [userData]="user" ></app-user-verification>
      </app-data-widget>
    </div>

    <app-data-widget class="relative" [widgetCaption]="'Account settings'">
      <div class="relative">
        <div class="spinner" *ngIf="inProgress">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
  
        <div class="user-setting margin-bottom-50">
          <h2 class="mat-h2 black-08">Email</h2>  
          <div class="mat-caption black-06">You logged in as: </div>
          <div class="mat-body-2 black-08">{{displayName}}</div>
          <div class="margin-top-20" *ngIf="hasPassword && changeType!=='email'">
            <span><button mat-raised-button 
                (click)="changeType = 'email'">Change email</button></span>
          </div>
    
          <div class="email-form margin-top-20 margin-bottom-20" *ngIf="changeType==='email'">
            <form [formGroup]="emailChangeForm">
              <div>
                <div class="margin-bottom-20">

                  <mat-form-field class="fullwidth">
                    <input matInput type="email" placeholder="New email" formControlName="email"
                      class="form-control">
                    <mat-error *ngIf="!emailChangeForm.controls['email'].valid">The provided email is not valid</mat-error>
                  </mat-form-field>

                  <mat-form-field class="fullwidth">
                    <input matInput type="password" placeholder="Your password" class="form-control"
                      formControlName="password">
                  </mat-form-field>
    
                  <div *ngIf="errorMessage" class="mat-caption color-warning">
                    <span>{{errorMessage}}</span>
                  </div>
                </div>
    
                <div class="register-form-button">
                  <span class="margin-right-10"><button color="primary" mat-raised-button type="submit"
                      [disabled]="emailChangeForm.invalid" (click)="tryToChangeEmail(emailChangeForm.value)"
                      class="btn btn-default">Change email</button></span>
                  <button mat-raised-button (click)="cancelChangeOperation()"
                    class="btn btn-default">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>


        <div class="user-setting margin-bottom-50" *ngIf="hasPassword">
        
          <h2 class="mat-h2 black-08">Password</h2>
          <div class="margin-top-20" *ngIf="changeType!=='password'">
            <span><button mat-raised-button  (click)="changeType = 'password'">Change password</button></span>
          </div>
        
        
          <div class="password-form margin-top-20 margin-bottom-20" *ngIf="changeType==='password'">
            <form [formGroup]="passwordChangeForm">
              <div>
                <div class="margin-bottom-20">
        
                  <mat-form-field class="fullwidth">
                    <input matInput type="password" placeholder="Current password" class="form-control"
                      formControlName="oldPassword">
                  </mat-form-field>
        
                  <mat-form-field class="fullwidth">
                    <input matInput type="password" placeholder="New password" class="form-control" formControlName="password">
        
                    <mat-error *ngIf="!passwordChangeForm.controls['password'].valid">
                      The password is too simple. Min. 8 characters, contain at least 1 [a-z], 1 [A-Z], 1 number.
                    </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="fullwidth">
                    <input matInput type="password" placeholder="Confirm password" class="form-control"
                      formControlName="passwordConfirm" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="passwordChangeForm.hasError('notSame')">
                      Passwords do not match
                    </mat-error>
                  </mat-form-field>
        
                  <div *ngIf="errorMessage" class="color-warning">
                    <span class="mat-caption">{{errorMessage}}</span>
                  </div>
                </div>
                <div class="register-form-button">
                  <span class="margin-right-10"><button color="primary" mat-flat-button type="submit"
                      [disabled]="passwordChangeForm.invalid" (click)="tryToChangePassword(passwordChangeForm.value)"
                      class="btn btn-default">Change password</button></span>
                  <button mat-raised-button (click)="cancelChangeOperation()" class="btn btn-default">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="user-setting">
          <h2 class="mat-h2 black-08">Delete account</h2>

          <div class="map-body black-08 margin-bottom-10">Deleting your account will permanently remove all data associated with the account. You will not be able to reactivate the account.</div>

          <div class="margin-top-20"  *ngIf="hasPassword && changeType!=='deleteAccount'">
            <span><button mat-raised-button 
                (click)="changeType = 'deleteAccount'">Delete your account</button></span>
          </div>

          <div class="margin-top-20"  *ngIf="!hasPassword">
            <button mat-raised-button (click)="deleteAccount()">
              <span class="margin-right-10"><fa-icon class="black-06" [icon]="['fas', 'trash']"></fa-icon></span>Delete your account
            </button>
          </div>

          <div class="email-form margin-top-20 margin-bottom-20" *ngIf="changeType==='deleteAccount'">
            <form [formGroup]="deleteAccountForm">
              <div>
                <div class="map-body black-08 margin-bottom-10 color-warning">Are you sure you want to delete your account irrecoverably, projects, and all existing API keys? This operation cannot be reverted.</div>
                <div class="map-body black-08 margin-bottom-10">To continue, please enter your password and click "Delete Account."</div>
                <div class="margin-bottom-20">
                  <mat-form-field class="fullwidth">
                    <input matInput type="password" placeholder="Your password" class="form-control"
                      formControlName="password">
                  </mat-form-field>
    
                  <div *ngIf="errorMessage" class="mat-caption color-warning">
                    <span>{{errorMessage}}</span>
                  </div>
                </div>
    
                <div class="register-form-button">
                  <span class="margin-right-10"><button color="primary" mat-raised-button type="submit"
                      [disabled]="deleteAccountForm.invalid" (click)="tryToDeleteAccount(deleteAccountForm.value)"
                      class="btn btn-default">Delete account</button></span>
                  <button mat-raised-button (click)="cancelChangeOperation()"
                    class="btn btn-default">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </app-data-widget>
  </div>
</div>