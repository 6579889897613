import { Component, NgZone, OnInit } from '@angular/core';
import { UserDataService, UserDocument, ProjectMinimal } from '../../services/user-data.service';
import { ProjectService, Project } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { NewProjectDialogComponent } from '../new-project-dialog/new-project-dialog.component';
import { Router } from '@angular/router';
import { KeyManagementService } from '../../services/key-management.service';
import { User } from '@firebase/auth';
import { AuthService } from '../../services/auth.service';
import { DocumentReference } from 'firebase/firestore';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    standalone: false
})
export class ProjectsComponent implements OnInit {

  projects: ProjectMinimal[];
  user: User;

  constructor(private userDataService: UserDataService, private projectService: ProjectService, 
    private dialog: MatDialog, private router: Router, private keyManagementService: KeyManagementService, private authService: AuthService, private ngZone: NgZone) {
    this.updateData(userDataService.getUserDocument());

    userDataService.getUserDocumentChangeEvent().subscribe((userDocument: UserDocument) => {
      this.updateData(userDocument);
    });
  }

  updateData(userDocument) {
    if (!userDocument) {
      return;
    }

    this.ngZone.run(() => {
      this.projects = this.userDataService.getProjects();
      this.user = this.authService.getCurrentUser();
    });
  }

  openNewProjectDialog() {
    const dialogRef = this.dialog.open(NewProjectDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(newProject => {
      if (newProject) {
        this.projectService.addNewProject(newProject, this.userDataService.getUserId()).then((projectRef: DocumentReference) => {
          newProject.id = projectRef.id;
          this.keyManagementService.createApiKey(newProject.id, this.keyManagementService.generateComment(this.userDataService.getUserId(), newProject.id));
          return this.userDataService.addNewProject(newProject);
        }).then(() => {
          this.router.navigate([`/${newProject.type}`, newProject.id]);
        }, error => {
          this.projectService.addLog(error);
        });
      }
    }); 
  }

  openProjectDetails(project: ProjectMinimal) {
    this.router.navigate([`/${project.type}`, project.id]);
  }

  ngOnInit() {

  }
}
