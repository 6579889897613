import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/user/services/project.service';
import { ActiveApiProjectService } from '../../services/active-api-project.service';

@Component({
    selector: 'app-api-project',
    templateUrl: './api-project.component.html',
    styleUrls: ['./api-project.component.scss'],
    standalone: false
})
export class ApiProjectComponent implements OnInit, OnDestroy {

  routeParamSub: Subscription;
  projectSub: Subscription;

  activePanelId: string;
  activePanelIdSub: Subscription;

  keys: string[] = [];
  project: Project;

  constructor(private route: ActivatedRoute,
    private activeProjectService: ActiveApiProjectService) {
    
    this.activePanelIdSub = this.activeProjectService.getActivePanelIdObservable().subscribe(id => {
      this.activePanelId = id;
    });

    this.routeParamSub = this.route.params.subscribe(params => {
      const projectId = params['id'];
      const panelId = params['panelId'];

      this.activeProjectService.init(projectId, panelId);
    });

    this.projectSub = this.activeProjectService.getActiveProjectObservable().subscribe((project) => {
      if (!project) {
        return;
      }
      this.project = project;
      this.keys = project ? this.activeProjectService.getActiveProjectKeysIds() : [];
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.routeParamSub.unsubscribe();
    this.activePanelIdSub.unsubscribe();
  }
}
