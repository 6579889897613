import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-api-pricing-plan-item',
    templateUrl: './api-pricing-plan-item.component.html',
    styleUrls: ['./api-pricing-plan-item.component.scss'],
    standalone: false
})
export class ApiPricingPlanItemComponent implements OnInit {

  @Input()
  isCurrentPlan: boolean;

  @Input()
  renewalDate: string;

  @Input()
  expirationDate: string;

  @Input()
  onlyInfo: boolean;

  @Input()
  contactUs: boolean;

  @Input()
  plan: PricingPlan;

  @Input()
  custom: boolean;

  @Input()
  currency: 'eur'|'usd';

  @Input()
  billingCycle: 'yearly' | 'monthly';

  @Output()
  subscribe: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  subscribeClicked() {
    this.subscribe.emit();
  }

  generateTooltip(items: string[]) {
    return items.join(" or \r\n");
  }
}

export interface PricingPlan {
  type: 'platform' | 'geocoding' | 'batch-geocoding' | 'optional' | 'credits-based'
  id: string;
  label: string;
  credits?: number;
  color?: string;
  items: string[];
  conditions: string[];
  promotion?: string;
  price: PriceOptions;
  product: ProductOptions;
  overquota: {[currency: string]: number},
  overquotaIncluded: boolean;
}

interface PriceOptions {
  monthly: {[currency: string]: number};
  yearly: {[currency: string]: number};
}

interface ProductOptions {
  monthly: string;
  yearly: string;
}