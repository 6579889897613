<div class="statistics-container margin-bottom-50">
  <app-data-widget [widgetCaption]="'Select dates and key'">
    <div class="conditions-container">
      <div class="key-filter">
        <mat-form-field class="key-selector-control">
          <mat-label>Key filter</mat-label>
          <mat-select [(ngModel)]="keyFilter">
            <mat-option class="all-options" value="all">
              All keys
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let key of apiKeys" [value]="key">
              <div class="mat-body">{{ maskKey(key) }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="date from">
        <mat-form-field class="picker-constrol">
          <input matInput [matDatepicker]="pickerFrom" placeholder="From date" [min]="oneYearAgo" [max]="toDate.value"
            [formControl]="fromDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="date to">
        <mat-form-field class="picker-constrol">
          <input matInput [min]="fromDate.value" [matDatepicker]="pickerTo" placeholder="To date"
            [formControl]="toDate">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="button">
        <button mat-raised-button color="primary" [disabled]="!fromDate.value || !toDate.value"
          (click)="updateStatistics()">Get statistics</button>
      </div>
    </div>
  </app-data-widget>

  <app-data-widget [widgetCaption]="'Statistics by day'">
    <app-overview-statistics #chart [project]="project" [key]="keyFilter" [fromDate]="fromDate.value" [toDate]="toDate.value"></app-overview-statistics>
  </app-data-widget>

  <app-data-widget [widgetCaption]="'Statistics by API'">
    <div class="results-container black-08">
      <div class="statistics-line header">
        <span class="statistics-value name mat-body-2">Service name / API</span>
        <span class="statistics-value value mat-body-2">Number of requests</span>
        <span class="statistics-value value mat-body-2">Cost in <a href="https://www.geoapify.com/pricing-details/"
            target="_blank" rel="noopener noreferrer">Credits</a></span>
      </div>
      <div class="statistics-line" *ngFor="let key of statisticsToDisplay">
        <span class="statistics-value name mat-body">{{statisticsNames[key]}}</span>
        <span class="statistics-value value mat-body">{{statistics[key].toLocaleString('en-US')}}</span>
        <span class="statistics-value value mat-body">{{costs[key].toLocaleString('en-US')}}</span>
      </div>
      <div class="statistics-line footer">
        <span class="statistics-value name mat-body-2">Total</span>
        <span class="statistics-value value mat-body-2">{{totalRequests.toLocaleString('en-US')}}</span>
        <span class="statistics-value value mat-body-2">{{totalCosts.toLocaleString('en-US')}}</span>
      </div>
      <div class="spinner-container" *ngIf="showSpinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </app-data-widget>
</div>