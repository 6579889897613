import { Component, OnInit, NgZone } from '@angular/core';
import { Project, ProjectService } from 'src/app/user/services/project.service';
import { Subscription } from 'rxjs';
import { ActiveApiProjectService } from '../../services/active-api-project.service';
import { HttpClient } from '@angular/common/http';
import { PricingPlan } from 'src/app/common-components/components/api-pricing-plan-item/api-pricing-plan-item.component';
import { KeyManagementService } from 'src/app/user/services/key-management.service';
import { UserDataService } from 'src/app/user/services/user-data.service';

@Component({
    selector: 'app-subscription-change',
    templateUrl: './subscription-change.component.html',
    styleUrls: ['./subscription-change.component.scss'],
    standalone: false
})
export class SubscriptionChangeComponent implements OnInit {

  pricingPlans: PricingPlan[];
  planName: string;

  project: Project;
  projectSub: Subscription;

  inProgress: boolean;

  constructor(private activeProjectservice: ActiveApiProjectService, 
    http: HttpClient, ngZone: NgZone, private projectService: ProjectService,
    keyManagementService: KeyManagementService) {
    // load pricing plans
    http.get("assets/json/pricing-plans-new.json").subscribe((pricingPlans: any) => {
      this.pricingPlans = pricingPlans;
      this.projectSub = this.activeProjectservice.getActiveProjectObservable().subscribe((project) => {
        ngZone.run(() => {
          this.project = project;

          if (project) {
            const planId = project.billing.pendingPlan || project.billing.plan;
            const plan = this.pricingPlans.find(plan => plan.id === planId);
            this.planName = plan.label;
          
            if (project.billing.pendingPlan) {
              this.projectService.confirmProjectPendingPlan(this.activeProjectservice.getActiveProjectId(), project.billing.pendingPlan);
              Object.keys(this.project.keys || {}).forEach(key => {
                keyManagementService.updateApiKey(key, {},keyManagementService.generateComment(project.owner, this.activeProjectservice.getActiveProjectId(), project.billing.pendingPlan));
              });
            }
          }
        });
      });

    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.projectSub.unsubscribe();
  }
}
