import {Component, OnDestroy, NgZone} from '@angular/core';
import {AuthService} from "../../user/services/auth.service";
import {Subscription} from "rxjs";
import {ChangeDetectorRef} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
    styleUrls: ['./top-navigation.component.scss'],
    standalone: false
})
export class TopNavigationComponent implements OnDestroy {

  private userChangeEventSubscription: Subscription;
  isUserLoggedIn: boolean;
  isRegularUser: boolean;

  displayName: string;

  constructor(private authService: AuthService, private router: Router, private ngZone: NgZone) {
    this.userChangeEventSubscription = authService.getUserChangeEvent().subscribe((user) => {
      this.ngZone.run(() => {
        this.isUserLoggedIn = !!user;
        this.isRegularUser = authService.isRegularUser();

        this.generateDisplayName(user);
      });
    });
  }

  public signOut() {
    this.authService.doLogout().then(() => {
      this.ngZone.run(() => {
        if (this.isRegularUser) {
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/manifold/login']);
        }
      });
    });
  }

  generateDisplayName(user) {

    if (!user) {
      this.displayName = '';
      return;
    }

    if (user.displayName) {
      this.displayName = user.displayName + (user.email ? ` (${user.email})` : '')
    } else {
      this.displayName = user.email;
    }
  }

  ngOnDestroy() {
    this.userChangeEventSubscription.unsubscribe();
  }
}
