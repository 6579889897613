import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit {

  links = [
    {label: "Privacy policy", link: "https://www.geoapify.com/privacy-policy/"},
    {label: "Cookie policy", link: "https://www.geoapify.com/cookie-policy/"},
    {label: "Terms", link: "https://www.geoapify.com/term-and-conditions/"},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
